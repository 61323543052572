import React from "react";

const Playground = () => {
  return (
    <div className="min-h-screen p-4">
      <p>Hey.</p>
    </div>
  );
};

export default Playground;
